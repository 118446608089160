/* ReplyBar.css */
.reply-bar .message {
  position: relative;
}

/* ReplyBar.css */
.message code {
  position: relative; /* Important for positioning the copy button inside */
  background-color: #f5efdb; /* 深色背景 */
  color: #000208; /* 浅黄色文字 */
  font-family: "Fira Code", "Courier New", Courier, monospace; /* 设置字体 */
  padding: 0.5em; /* 内边距 */
  border-radius: 5px; /* 圆角边框 */
  display: block; /* 让代码块独占一行 */
  overflow-x: auto; /* 超出部分横向滚动 */
  white-space: pre; /* 保持空白字符 */
  margin-top: 0.5em; /* 顶部外边距 */
  margin-bottom: 0.5em; /* 底部外边距 */
}

.code-block {
  position: relative; /* Important for positioning the copy button inside */
  background-color: #f8e9b6;
  color: #000208;
  font-family: "Fira Code", "Courier New", Courier, monospace;
  padding: 0.5em;
  border-radius: 5px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.copy-button {
  position: absolute;
  top: 2px; /* 缩小垂直位置 */
  right: 2px; /* 缩小水平位置 */
  padding: 2px 5px;
  font-size: 0.8em; /* 缩小字体大小 */
  cursor: pointer;
  background-color: #5f5f5f;
  color: white;
  border: none;
  border-radius: 3px;
  z-index: 10; /* Ensure the button is above all other content */
}

.copied {
  background-color: #4caf50; /* Green background to indicate success */
}

.reply-bar {
  flex-grow: 1;
  overflow-y: auto; /* 添加滚动条 */
  padding: 10px;
}

.message {
  margin-bottom: 0px; /* 减小间距 */
  padding: 2px;
  border-radius: 5px;
}

/* Styling for user messages */
.message.user {
  background-color: #d2f3fe; /* 浅蓝色背景 */
  text-align: right;
  width: 80%; /* Smaller width */
  margin-left: auto; /* Right align */
}

/* Styling for AI messages */
.message.ai {
  text-align: left;
  width: 100%;
}

/* Styling for error messages */
.message-error {
  text-align: left;
}

/* Styling for code blocks */
.code-line {
  display: block;
  background-color: black;
  color: white;
  padding: 2px 5px;
  font-family: "Courier New", Courier, monospace;
  border-radius: 3px;
  white-space: pre;
}

table {
  border-collapse: collapse; /* 使表格边框合并为一个单一的边框 */
  width: 100%; /* 根据需要调整 */
  margin-bottom: 20px; /* 根据需要调整 */
}

table, th, td {
  border: 1px solid #cccccc; /* 设置边框颜色和宽度 */
}

th, td {
  padding: 8px; /* 设置单元格内边距 */
  text-align: left; /* 根据需要调整文本对齐方式 */
}

th {
  background-color: #f2f2f2; /* 设置表头背景色，根据需要调整 */
}