/* InputBar.css */

.input-bar {
  display: flex;
  width: 100%; /* 充满屏幕宽度 */
  padding: 10px;
  background-color: white;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.input-bar textarea {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #4a90e2; /* 边框颜色改为蓝色 */
  resize: none;
  outline: none; /* 移除聚焦时的默认轮廓线 */
}

.input-bar button {
  padding: 10px 20px;
  border: none;
  background-color: #4a90e2; /* 按钮背景颜色改为蓝色 */
  color: white; /* 按钮文字颜色改为白色 */
  cursor: pointer;
}

@media (max-width: 768px) {
  .input-bar {
    flex-direction: column;
    align-items: stretch;
  }
  .input-bar textarea {
    margin-bottom: 10px;
  }
  .input-bar button {
    width: 100%;
  }
}
