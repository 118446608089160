/* LoginForm.css */
.login-form {
  margin-top: 10px;
}

.login-form input {
  padding: 5px;
  margin-right: 10px;
}

.login-form button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #0056b3;
}
