/* TitleBar.css */
.title-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  background-color: #4a90e2;
  color: white;
}

.logo {
  height: 40px;
  margin-right: 10px; /* 调整间距 */
}

.title-text {
  flex-grow: 1;
  text-align: center;
  font-size: 120%;
  margin: 0; /* Reset default margins */
  line-height: 50px; /* Align vertically */
}

.user-id {
  /* 样式针对显示 userId 的元素 */
  cursor: pointer;
  margin-left: auto;
}

.login-form {
  /* 样式针对登录表单 */
  position: absolute;
  background-color: white;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.error {
  color: red;
}

/* 响应式设计：在较小屏幕尺寸上调整样式 */
@media screen and (max-width: 600px) {
  .title-bar {
    flex-direction: column;
    height: auto;
  }

  .logo,
  .title-text {
    margin-top: 10px;
  }

  .user-id {
    margin-top: 10px;
    margin-left: 0;
  }

  .login-form {
    right: 10px; /* 在小屏幕上向内缩进 */
  }
}
