.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.control-button {
  font-size: 0.8em;
  padding: 5px 10px;
  border: 1px solid lightgray; /* 你可能想要移除这行，因为接下来你会设置背景颜色 */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  flex-grow: 0;
  background-color: #4a90e2; /* 蓝色背景 */
  color: white; /* 白色文字 */
  border-radius: 5px; /* 圆角边框 */
  cursor: pointer; /* 鼠标悬停时显示手型光标 */
}

.uploaded-files-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
}

.uploaded-file {
  display: flex;
  align-items: center;
  gap: 10px;
}

.remove-file-button {
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}